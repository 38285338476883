import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse, Subscription } from '@lib/interfaces';
import { EventMediator } from '@lib/mediator';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {
    activeSub$ = new BehaviorSubject<Subscription | null>(null);

    private readonly _http = inject(HttpClient);
    private readonly _eventMediator = inject(EventMediator);
    private readonly _apiUrl = environment.apiUrl;

    public get activeSub(): Subscription | null {
        return this.activeSub$.getValue();
    }

    init(): void {
        this.setSub();
        this._eventMediator.timerStateChanged.subscribe((isDone: boolean) => {
            if (isDone) this.setSub();
        });
    }

    setSub(): void {
        this._http.get<ApiResponse<Subscription>>(this._apiUrl + '/subscriptions/active').subscribe({
            next: (response) => this.activeSub$.next(response.data),
            error: () => this.activeSub$.next(null),
        });
    }
}
